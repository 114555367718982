<template>
  <div
    class="exhi-app"
    :style="`${mobile && !shrinkLeftMenu ? 'opacity: 0' : 'opacity: 1'}`"
  >
    <div class="exhi-container">
      <div class="cardList">
        <div class="cards__wrapper" ref="cardsWrapper">
          <div class="card current--card" @click="handleImgPreview(0, $event)">
            <div class="card__image">
              <img :src="get(renderedImageUrls[0], 'azure_blob')" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="infoList">
        <div class="info__wrapper" ref="infoWrapper">
          <div class="info current--info">
            <h1 class="text name">
              {{ get(renderedImageUrls[0], "Name_En") }}
            </h1>
            <h4 class="text year">{{ get(renderedImageUrls[0], "Year") }}</h4>
            <p class="text painter">
              {{ get(renderedImageUrls[0], "Painter_En") }}
            </p>
            <p class="text museum">
              {{ get(renderedImageUrls[0], "Museum_En") }}
            </p>
          </div>
        </div>
      </div>

      <div class="app__bg" ref="appBg">
        <div class="app__bg__image current--image">
          <img :src="get(renderedImageUrls[0], 'azure_blob')" alt="" />
        </div>
      </div>
    </div>

    <div class="loading__wrapper">
      <div class="loader--text">Loading...</div>
      <div class="loader">
        <span></span>
      </div>
    </div>

    <image-preview
      :image-urls="Array(1).fill(this.currentImageUrl)"
      :visible.sync="visible"
      :isMobile="mobile"
      :isIpad="ipad"
      :metaInfo="metaInfo"
      :liked="likeit"
      @like="handleLikeAction"
    ></image-preview>
  </div>
</template>

<script>
import gsap from "gsap";
import { get } from "lodash";
import imagesLoaded from "imagesloaded";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SoloContainer",

  metaInfo() {
    const { Name_En, Painter_En, azure_blob } = this.activeSinglePainting || {};
    return {
      // title will be injected into parent titleTemplate
      title: `${Name_En ?? ""} - ${Painter_En ?? ""}`,
      meta: [
        {
          property: "og:title",
          content: `${Name_En} - ${Painter_En}`,
          template: (chunk) => `${chunk} | Boost Art Net`,
        },
        {
          property: "og:image",
          content: `${azure_blob}`,
        },
        {
          property: "twitter:title",
          content: `${Name_En} - ${Painter_En}`,
          template: (chunk) => `${chunk} | Boost Art Net`,
        },
        {
          property: "twitter:image",
          content: `${azure_blob}`,
        },
      ],
    };
  },

  props: ["paintingIndex"],

  data() {
    return {
      ipad: false,
      likeit: false, // like this image or not
      visible: false, // whether image previewer visible
      startPosition: 0,
      mobile: false,
      paintingInfo: [{}],
      currentImageUrl:
        "https://westartbucket101.blob.core.windows.net/2021/Modigliani053.png",
    };
  },

  computed: {
    ...mapGetters({
      activeSinglePainting: "getActiveSinglePainting",
      shrinkLeftMenu: "getShrinkLeftMenu",
      collectionResult: "getCollectionResult",
      hardwareBackActive: "getHardwareBackActive",
    }),

    renderedImageUrls() {
      return this.paintingInfo.map((el) => {
        return Object.assign(
          {},
          {
            azure_blob: el.azure_blob,
            Painter_En: el.Painter_En,
            Year: el.Year,
            Name_En: el.Name_En,
            Museum_En: el.Museum_En,
          }
        );
      });
    },

    cardsContainerEl() {
      return this.$refs.cardsWrapper;
    },

    cardInfosContainerEl() {
      return this.$refs.infoWrapper;
    },

    appBgContainerEl() {
      return this.$refs.appBg;
    },

    firstPaintedDetector() {
      if (this.$refs.cardsWrapper) {
        return (
          this.$refs.cardsWrapper.lastChild.lastChild.lastChild
            .getAttribute("src")
            ?.slice(0, 5) === "https"
        );
      }
      return undefined;
    },

    metaInfo() {
      const url = `https://www.boost-art.net/painting/${this.paintingIndex}?share`;
      const { Name_En, Painter_En } = this.activeSinglePainting || {};
      const title = this.$t("00003");
      const description =
        "Boost Art Net APP presents. Install: Admire thousands of captivating masterpieces right in your pocket; Museum: The exact destination of masterpiece to help plan your art travel well; Wander: Scroll and browse the chronicle of Western fine art along the way; Search: Handy search entry to reach out to your favorite painters and paintings.";
      const media = this.currentImageUrl;
      return {
        url,
        title: `${Name_En} - ${Painter_En} | ${title}`,
        description,
        media,
        hashtags: "art",
      };
    },
  },

  watch: {
    paintingIndex(newValue, oldValue) {
      // Carousel Rerender watching genre changes (triggered by leftmenu SearchItem)
      console.log("New:", newValue, "Old:", oldValue);
      // reset loader bar to 0%
      const loaderEl = document.querySelector(".loader span");
      gsap.set(loaderEl, {
        duration: 1,
        scaleX: 0,
        backgroundColor: `hsl(${0 * 120}, 100%, 50%)`,
      });
      // keep leftmenu overlay the loading layout
      const leftMenu = document.querySelector(".navigation-view-container");
      gsap.set(leftMenu, {
        "z-index": "250",
      });
      // show the loading layout
      const loadingWrapper = document.querySelector(".loading__wrapper");
      gsap.set(loadingWrapper, {
        "pointer-events": "unset",
        opacity: "unset",
      });
      // move the previous image boxes below the screen
      const cardsContainerEl = document.querySelector(".cards__wrapper");
      gsap.set(cardsContainerEl.children, {
        "--card-translateY-offset": "100vh",
      });

      this.executeWholeBoostArt();
    },
    hardwareBackActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.visible = false;
          this.updateModalVisible(this.visible);
        }
      },
    },
    visible(newValue) {
      this.updateModalVisible(newValue);
    },
  },

  methods: {
    ...mapActions([
      "fetchSinglePainting",
      "fetchCollectionResult",
      "addLikeToCollection",
      "removeLikeFromCollection",
      "updateModalVisible",
    ]),

    get, // used in tempalate

    handleImgPreview(index, $event) {
      // prevent the image preview action when the left menu is shown and mobile
      if (this.mobile && !this.shrinkLeftMenu) return;
      const boxClass = $event.target.classList.value;
      if (boxClass.search("current--card") > -1) {
        this.currentImageUrl = $event.target
          .querySelector(".card__image img")
          .getAttribute("src");
        this.$nextTick(async () => {
          this.visible = true;
          this.updateModalVisible(this.visible);
          this.startPosition = index;

          // Get like information
          // const token = this.$cookie.get("boost-art-net-token") || "";
          // await this.fetchCollectionResult(token);
          await this.fetchCollectionResult();
          const that = this;
          this.$nextTick(() => {
            if (
              that.collectionResult.some(
                (el) => el?.Painting_Index === that.paintingIndex
              )
            ) {
              that.likeit = true;
            } else {
              that.likeit = false;
            }
          });
        });
      }
    },

    handleLikeAction(yes) {
      // const token = this.$cookie.get("boost-art-net-token") || "";
      if (yes) {
        this.likeit = true;
        // this.addLikeToCollection({ token: token, index: this.paintingIndex });
        this.addLikeToCollection({ index: this.paintingIndex });
      } else {
        this.likeit = false;
        // this.removeLikeFromCollection({
        //   token: token,
        //   index: this.paintingIndex,
        // });
        this.removeLikeFromCollection({
          index: this.paintingIndex,
        });
      }
    },

    initCardEvents() {
      const currentCardEl =
        this.cardsContainerEl.querySelector(".current--card");
      currentCardEl.addEventListener("pointermove", this.updateCard);
      currentCardEl.addEventListener("pointerout", (e) => {
        this.resetCardTransforms(e);
      });
    },

    resetCardTransforms(e) {
      const card = e.currentTarget;
      const currentInfoEl =
        this.cardInfosContainerEl.querySelector(".current--info");
      gsap.set(card, {
        "--current-card-rotation-offset": 0,
      });
      gsap.set(currentInfoEl, {
        rotateY: 0,
      });
    },

    updateCard(e) {
      const card = e.currentTarget;
      const box = card.getBoundingClientRect();
      const centerPosition = {
        x: box.left + box.width / 2,
        y: box.top + box.height / 2,
      };
      let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
      gsap.set(card, {
        "--current-card-rotation-offset": `${angle}deg`,
      });
      const currentInfoEl =
        this.cardInfosContainerEl.querySelector(".current--info");
      gsap.set(currentInfoEl, {
        rotateY: `${angle}deg`,
      });
    },

    removeCardEvents(card) {
      card.removeEventListener("pointermove", this.updateCard);
    },

    initializeAnimatedImages() {
      const cardsContainerEl = document.querySelector(".cards__wrapper");

      const cardInfosContainerEl = document.querySelector(".info__wrapper");
      this.initCardEvents();

      const init = () => {
        let tl = gsap.timeline();
        tl.to(cardsContainerEl.children, {
          delay: 0.15,
          duration: 0.5,
          stagger: {
            ease: "power4.inOut",
            from: "right",
            amount: 0.1,
          },
          "--card-translateY-offset": "0",
        }).to(
          cardInfosContainerEl
            .querySelector(".current--info")
            .querySelectorAll(".text"),
          {
            delay: 0.5,
            duration: 0.4,
            stagger: 0.1,
            opacity: 1,
            translateY: 0,
          }
        );
      };

      const waitForImages = () => {
        const images = [...document.querySelectorAll(".exhi-container img")];
        const totalImages = images.length;
        let loadedImages = 0;
        const loaderEl = document.querySelector(".loader span");

        gsap.set(cardsContainerEl.children, {
          "--card-translateY-offset": "100vh",
        });
        gsap.set(
          cardInfosContainerEl
            .querySelector(".current--info")
            .querySelectorAll(".text"),
          {
            translateY: "40px",
            opacity: 0,
          }
        );

        images.forEach((image) => {
          imagesLoaded(image, (instance) => {
            if (instance.isComplete) {
              loadedImages++;
              let loadProgress = loadedImages / totalImages;

              gsap.to(loaderEl, {
                duration: 1,
                scaleX: loadProgress,
                backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%)`,
              });

              if (totalImages == loadedImages) {
                gsap
                  .timeline()
                  .to(".loading__wrapper", {
                    // delay 1 second to resolve cached image scenario and display
                    // the loading bar as full
                    delay: 1,
                    duration: 0.8,
                    opacity: 0,
                    pointerEvents: "none",
                  })
                  .call(() => init());
              }
            }
          });
        });
      };

      waitForImages();
    },

    executeWholeBoostArt() {
      const loaderEl = document.querySelector(".loader span");
      gsap.to(loaderEl, {
        duration: 1,
        scaleX: 0,
        backgroundColor: `hsl(${0 * 120}, 100%, 50%)`,
      });

      // animation effect
      const func = () => {
        this.$nextTick(() => {
          if (this.firstPaintedDetector) {
            this.initializeAnimatedImages();
          } else {
            setTimeout(() => {
              this.initializeAnimatedImages();
            }, 1000);
          }
        });
      };

      if (
        this.paintingIndex ===
        get(this.activeSinglePainting, "Painting_Index", "")
      ) {
        this.paintingInfo = [this.activeSinglePainting]; // [{}]
        func();
      } else {
        this.fetchSinglePainting({ index: this.paintingIndex }).then(
          (paintingInfo) => {
            this.paintingInfo = [paintingInfo]; // [{}]
            func();
          }
        );
      }
    },
  },

  mounted() {
    this.mobile = window.innerWidth <= 450;
    this.ipad = Boolean(
      !!navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/) || // browser devtools ipad
        (/Macintosh/i.test(navigator.userAgent) && // real ipad devices
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 1)
    );
    // In case of visible leftmenu, keep leftmenu overlay the loading layout
    const leftMenu = document.querySelector(".navigation-view-container");
    gsap.set(leftMenu, {
      "z-index": "250",
    });
    // Initial Carousel Render
    this.executeWholeBoostArt();
  },
};
</script>

<style lang="less">
@import "./style.less";
</style>
